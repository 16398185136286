import React, { useState, useEffect } from "react";
import superadminapi, { fetchDefaultPrice, addUpdateDefaultPrices } from "../../Base/SuperAdminConfig";
import Message from "../../Components/Message/Message";
import Loading from "../../Components/Loading/Loading";
import styles from './apis.module.css'

export default function APIs() {
    const [data, setData] = useState([]);
    const [ apiName, setApiName ] = useState('');
    const [ pid, setPid ] = useState('');
    const [ service, setService ] = useState('');
    const [ category, setCategory ] = useState('');
    const [ amount, setAmount ] = useState('');
    // const [addModal, setAddModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const fetchPricing = () => {
        superadminapi.get(fetchDefaultPrice)
        .then((res) => {
            if (res.data.code === 200) {
                setLoading(false);
                setData(res.data.result);
               
            }
        })
        .catch((error) => {
            console.error(error);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchPricing();
        // eslint-disable-next-line 
    }, []);


    const viewDetails = (x) => {
        setModal(true);
        setApiName(x.apiName);
        setPid(x.PID);
        setService(x.services);
        setCategory(x.category);
        setAmount(x.amount);
    }

    const addUpdateFunction = () => {
        superadminapi.post(addUpdateDefaultPrices, {
            apiName: apiName,
            services: service,
            PID: pid,
            category: category,
            amount: Number(amount)
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200) {
                fetchPricing();
                closeModal();
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch(err => {
            console.error(err);
            setLoading(false);
            setMessageClass('error');
            setMessage('Something went wrong, Try again.');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const closeModal = () => {
        setModal(false);
        setApiName('');
        setPid('');
        setService('');
        setCategory('');
        setAmount('');
    }

    return (
        <>
            {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
            {/* editFunctionadd */}
            {modal && <div className="modal-container">
                <div className="modal-background" onClick={()=>closeModal()}></div>
                <div className="modal-box" style={{background:'#FFF'}}>
                    <div className={styles["modal-header"]}> API detail </div>
                    <div className={styles["modal-body"]}>
                        <div>
                            <label>API name</label>
                            <input type="text" placeholder="Enter apiName" value={apiName} onChange={e => setApiName(e.target.value)} />
                        </div>
                        <div>
                            <label>PID</label>
                            <input type="text" placeholder="Enter PID" value={pid} onChange={e => setPid(e.target.value)} />
                        </div>
                        <div>
                            <label>Category</label>
                            <input type="text" placeholder="Enter Category" value={category} onChange={e => setCategory(e.target.value)} />
                        </div>
                        <div>
                            <label>Service</label>
                            <input type="text" placeholder="Enter Service" value={service} onChange={e=>setService(e.target.value)}/>
                        </div>
                        
                        <div>
                            <label>Amount</label>
                            <input type="number" placeholder="Enter amount" value={amount} onChange={e => setAmount(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles["modal-footer"]}>
                        <div>
                            {
                                loading
                                ?<button className="btn primary-button" disabled>Wait ...</button>
                                :<button className="btn primary-button" onClick={() => addUpdateFunction()}>Save</button>
                            }
                        </div>
                        <div>
                            <button className="btn secondary-button" onClick={() => closeModal()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>}
            {loading ? (
                <Loading />
            ) : (
                <>
                    
                    <div className={styles['add-box']} >
                        <button className="btn primary-button mb-3" onClick={() => setModal(true)}>Add new</button>
                    </div>
                    <div className={styles['transactions-box']}>
                        <div className={styles['all-list-head']}>
                            <p>#</p>
                            <p>PID</p>
                            <p>API name</p>
                            <p>Category</p>
                            <p>Amount</p>
                            <p>Details</p>
                        </div>
                        {
                        <>
                            {data.map((item, i) => (
                                <div className={styles['all-list-body']} key={i}>
                                    <p><span>#</span> {i + 1}</p>
                                    <p><span>PID:</span> {item.PID}</p>
                                    <p><span>API name:</span> {item.apiName}</p>
                                    <p><span>Category:</span> {item.category}</p>
                                    <p><span>Amount:</span> {item.amount}</p>
                                    <p className={styles['view-detail']} onClick={() => viewDetails(item)}>View Details</p>
                                </div>
                            ))}
                        </>
                        }
                    </div>
                </>
            )}
        </>
    );
}